import * as React from 'react';
import {sortBy, isNil, range} from 'lodash';
import {fetchEnvs, fetchClients, scheduleFlowRuns} from '../../http/api';
import {Paper, Stack, Box, Typography} from '@mui/material';
import {Env, Client, selectedYear} from "../../store/features/envSlice";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface RecapPopUpType {
    enabled: boolean,
    clients: ClientFlow[],
    clientYears: selectedYear[],
    deployment: string,
    runPhase: string
    handleSendFlowRun: any,
}

interface ClientFlow {
    id: number
    name: string
    allYears: string[]
    selected: boolean
}

interface UniqueButtonType {
    array: string[],
    value: string,
    setValue: (value: string) => void,
}

interface MultipleButtonType {
    array: ClientFlow[],
    setArray: (array: ClientFlow[]) => void,
}

interface MultipleRowButtonType {
    array: ClientFlow[],
    value: selectedYear[],
    setValue: (value: selectedYear[]) => void,
}

interface RunSelectorType {
    deployment: string,
    setDeployment: any,
    runPhase: string,
    setRunPhase: any,
    clients: ClientFlow[],
    setClients: any,
    clientYears: selectedYear[],
    setClientYears: any,
}


const RecapButton = ({enabled, clients, clientYears, deployment, runPhase, handleSendFlowRun}: RecapPopUpType): any => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleAgreeButton = () => {
        handleSendFlowRun();
        setOpen(false);
    };

    const getClientName = (sy: selectedYear) => (clients.find((c) => (c.id === sy.id)))?.name;

    const clientYearsTextRows = sortBy(clientYears, ["id", "year"]).map((c) => `${getClientName(c)} - ${c.year}`)
    const textRows = [
        `Deployment: ${deployment}`,
        `Run Phase: ${runPhase}`,
        ...clientYearsTextRows
    ];

    return (
      <>
        <Button variant="contained" disabled={!enabled} onClick={handleClickOpen}>Recap</Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Flow runs recap</DialogTitle>
          <DialogContent>
              {textRows.map((text, key) => <DialogContentText key={key}>{text}</DialogContentText>)}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button onClick={handleAgreeButton} autoFocus>Agree</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

const selectableVariant = (isSelected: boolean) => isSelected ? "contained" : "outlined";

const GenerateUniqueButtons = ({array, value, setValue}: UniqueButtonType): any => {
    return <ButtonGroup>
      {array.map((s, key) => {
          const isSelected = s === value;
          return <Button
              key={key}
              onClick={() => setValue(isSelected ? "" : s)}
              variant={selectableVariant(isSelected)}
          >
              {s}
          </Button>
      })}
    </ButtonGroup>;
}

const GenerateMultipleButtons = ({ array, setArray }: MultipleButtonType): any => {
    const [isFull, setIsFull] = React.useState(false as boolean);

    React.useEffect(() => {
        const selected = isFull;
        setArray(array.map((client: ClientFlow) => ({...client, selected})));
    }, [isFull]);

    React.useEffect(() => {
       if (array.filter((client) => client.selected).length === 0) {
           setIsFull(false);
       }
    }, [array]);

    return <Box sx={{display:"flex", flexDirection:"row", flexWrap:"wrap"}}>
        <Button
            onClick={() => setIsFull(!isFull)}
            variant="outlined"
        >
            {isFull ? "reset" : "full"}
        </Button>
            {sortBy(array, ["id"]).map((c, key) => (
                <Button
                    key={key}
                    onClick={() => setArray(array.map((s) => (c === s) ? { ...s, selected: !s.selected } : s))}
                    variant={selectableVariant(c.selected)}
                >
                    {c.id} - {c.name}
                </Button>
            ))}
    </Box>;
}

const GenerateMultipleRowButtons = ({ array: allValues, value: values, setValue: setValues }: MultipleRowButtonType): any => {
    return allValues.map((c, key) => {
        if (!c.selected)
            return;

        const areEqual = (item0: selectedYear, item1: selectedYear) => (
            item0.id === item1.id && item0.year === item1.year
        );

        const isSelected = (item: selectedYear) => (
            !isNil(values.find((_item) => areEqual(item, _item)))
        );

        const handleButtonClick = (item: selectedYear) => {
            if (isSelected(item)) {
                setValues(values.filter((_item) => !areEqual(item, _item)))
            } else {
                setValues([...values, item]);
            }
        }

        return (
            <Box key={key}>
                <Typography variant='h5'>{c.name}</Typography>
                <ButtonGroup aria-label="outlined primary button group">
                    {c.allYears.map((y, key) => {
                        const item = {id: c.id, year: parseInt(y, 10)};
                        return <Button
                            key={key}
                            onClick={() => handleButtonClick(item)}
                            variant={selectableVariant(isSelected(item))}
                        >
                            {y}
                        </Button>;
                    })}
                </ButtonGroup>
            </Box>
        );
    });
}

const RunSelector = ({deployment, setDeployment, runPhase, setRunPhase, clientYears, setClientYears, clients, setClients}: RunSelectorType): any => {
    const RUN_PHASES = [ "full", "raw_phase", "clean", "compute" ];
    const maxYear = 2025;
    const _clients = [
        {name: "gdp", year: 2017},
        {name: "mtp", year: 2017},
        {name: "pdf_fr", year:2017},
        {name: "vulcania", year:2017},
        {name: "wbe", year:2019},
        {name: "cdv", year:2019},
        {name: "winoa", year:2017},
        {name: "clc", year:2021},
        {name: "cou", year:2017},
        {name: "ely", year:2019},
        {name: "gpm", year:2019},
        {name: "cra", year:2019},
        {name: "jda", year:2019},
        {name: "zpa", year:2018},
        {name: "gdb", year:2020}
    ];
    const allYears = (firstYear: number, lastYear: number) => range(firstYear, lastYear + 1).map((n) => `${n}`);
    const CLIENT_YEARS = _clients.map(
        (c) => [c.name, ...(allYears(c.year, maxYear))]
    )
    const [env, setEnv] = React.useState({} as Env);
    const [envList, setEnvList] = React.useState([] as Env[]);
    const [clientEnvList, setClientEnvList] = React.useState([] as Client[]);

    React.useEffect(() => {
        fetchEnvs().then((response) => {
            setEnvList(sortBy(response.data.results, ["env_name"]))
        })
    }, []);

    React.useEffect(() => {
        if (deployment === "") {
            setEnv({} as Env)
            setClients([]);
            setClientYears([]);
            setRunPhase("");
        } else for (const _dep of envList) {
                if (deployment === _dep.env_name)
                    setEnv(_dep)
        }
    }, [deployment]);

    React.useEffect(() =>{
        if (!isNil(env.base_url)) {
            fetchClients(env).then((response) => {
                setClientEnvList(response.data);
            });
    }
    }, [env]);

    React.useEffect(() => {
        if (deployment !== "") {
            setClients([]);
            setClientYears([]);
            setClients((prevClients: ClientFlow[]) => {
                let updatedClients = [...prevClients];
                for (let _client of clientEnvList) {
                    let client: ClientFlow = { id: _client.id, name: _client.name, allYears: [], selected: false};

                    ////// this part is supposed to be loaded by graphql
                    for (let _years of CLIENT_YEARS)
                        if (_years[0] === client.name) {
                            client.allYears = _years.slice(1);
                        }
                    /////////////////////////////////////////////////////

                    updatedClients = [...updatedClients, client];

                }
                return updatedClients;
            });
        }
    }, [clientEnvList]);

    React.useEffect(() => {
        setClientYears((prevClientYear: selectedYear[]) => {
            let updatedClientYear = [...prevClientYear];
            clientYears.map((s) => {
                clients.map((c) => {
                    if (s.id === c.id && !c.selected) {
                        updatedClientYear = [...updatedClientYear.filter((item) => item !== s)];
                    }
                });
            });
            return updatedClientYear;
        });
    }, [clients]);

    const phaseAndClients = <Box>
        <Typography variant='h5'>Run Phase</Typography>
        <GenerateUniqueButtons array={RUN_PHASES} value={runPhase} setValue={setRunPhase} />

        <Typography variant='h5'>Clients</Typography>
        <GenerateMultipleButtons  array={clients} setArray={setClients}/>
    </Box>;

    const _deployments = envList.map((_env) => {
        return _env.env_name
    });

    return <Box>
        <Typography variant='h5'>Deployment</Typography>
        <GenerateUniqueButtons array={_deployments} value={deployment} setValue={setDeployment} />
        {deployment !==  "" && phaseAndClients}
        <GenerateMultipleRowButtons array={clients} value={clientYears} setValue={setClientYears}/>
    </Box>;
}

export default function Riverflows() {
    const [deployment, setDeployment] = React.useState("" as string);
    const [runPhase, setRunPhase] = React.useState("" as string);
    const [clients, setClients] = React.useState([] as ClientFlow[])
    const [clientYears, setClientYears] = React.useState([] as selectedYear[])

    const handleSendFlowRun = () => {
        scheduleFlowRuns(deployment, runPhase, clientYears);
        setDeployment("");
    }

    return (
        <Stack spacing={3}>
            <Typography variant='h4' className='bold'>River Flows</Typography>
            <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                <RunSelector
                    deployment={deployment}
                    setDeployment={setDeployment}
                    runPhase={runPhase}
                    setRunPhase={setRunPhase}
                    clientYears={clientYears}
                    setClientYears={setClientYears}
                    clients={clients}
                    setClients={setClients}
                />
            </Paper>
            <Box>
                <RecapButton
                    enabled={clientYears.length !== 0 && runPhase !== ""}
                    clients={clients}
                    clientYears={clientYears}
                    deployment={deployment}
                    runPhase={runPhase}
                    handleSendFlowRun={handleSendFlowRun}
                />
            </Box>
        </Stack>
    )
}
